<template>
  <div
    class="modal fade"
    id="quote-request-modal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="cartModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content border-0">
        <div class="modal-header border-0">
          <h5 class="modal-title" id="cartModalLabel"></h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body pt-4">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="">
                  <div class="row">
                  <div class="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/c6BqspKBo23OxEbuP7gm4ZvzItu4Fc62dtaEbO5qoo9lk3i1bPhnWAQJ6DYPKQtXxN">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AtomButton from '@/components/atoms/AtomButton'
import AtomInput from '@/components/atoms/AtomInput'
import { mapFields } from 'vuex-map-fields'
import AtomTextarea from '@/components/atoms/AtomTextarea.vue'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Treeselect from '@riophae/vue-treeselect'
import VuePhoneNumberInput from 'vue-phone-number-input'
import * as i18nIsoCountries from 'i18n-iso-countries'

i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/fr.json'))
const Swal = require('sweetalert2')

export default {
  name: 'QuoteRequestModal',
  props: {
    courseRef: {
      type: String,
      required: false,
    },
    courseTitle: {
      type: String,
      required: false,
    },
  },
  components: {
    AtomButton,
    AtomInput,
    AtomTextarea,
    Treeselect,
    VuePhoneNumberInput,
  },
  data() {
    return {
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
        }
      },
      newBornsExperience: '',
      newBornsExperienceDuration: '',
      firstname: '',
      lastname: '',
      phone: '',
      email: '',
      company: '',
      address: '',
      zipcode: '',
      country: 'FR',
      city: '',
      message: '',
      accept: false,
      masked: true,
      countries: [],
      training: [],
      type: null,
      Toast: Swal.mixin({
        toast: true,
        position: 'center',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        },
      }),
      props: {
        defaultCountry: 'FR',
        preferredCountries: ['US', 'GB'],
        placeholder: 'Enter your phone',
        mode: 'international',
        inputOptions: {
          showDialCode: true,
        },
        disabledFormatting: false,
        wrapperClasses: 'country-phone-input',
      },
      isSaving: false,
    }
  },
  computed: {
    ...mapFields('app', [
      'quoteRequestModal',
      'user',
      'trainings',
      'devisTypes',
    ]),
    trainingsOptions() {
      return this.trainings.map((i) => {
        return {
          id: i.ref,
          name: i.name,
          //   children: i.sessions.data.map((j) => {
          //     return {
          //       id: j.id,
          //       name:
          //         j.courseName +
          //         ' ' +
          //         this.$moment(j.startAt).format('DD-MM-YYYY') +
          //         ' à ' +
          //         this.$moment(j.endAt).format('DD-MM-YYYY'),
          //     }
          //   }),
        }
      })
    },
    selectedTraining() {
      return []
        .concat(
          ...this.trainings
          // .map((i) =>
          //  {
          //   return i.sessions.data.map((j) => {
          //     j.courseToId = i.toId
          //     return j
          //   })
          // })
        )
        .filter((i) => {
          return this.training.includes(i.ref)
        })
    },
    selectedType() {
      return this.typesOptions.find((i) => i.id === this.type)
    },
    typesOptions() {
      return this.devisTypes.map((i) => {
        return {
          id: i.toId,
          name: i.name,
        }
      })
    },
  },
  created() {
    let countries = i18nIsoCountries.getNames('fr', { select: 'official' })
    this.countries = Object.entries(countries).map(([key, value]) => ({
      id: key,
      name: value,
    }))
    this.countries = this.$lodash.sortBy(this.countries, 'name')
  },
  mounted() {
    const script = document.createElement("script");
    script.src = "https://webforms.pipedrive.com/f/loader";
    script.async = true;
    document.body.appendChild(script);
  },
  watch: {
    user() {
      if (this.user) {
        this.firstname = this.user.firstname || ''
        this.lastname = this.user.lastname || ''
        this.email = this.user.email || ''
        this.phone = this.user.phone || ''
        this.country = this.user.countryCode || ''
        this.address = this.user.address || ''
        this.city = this.user.city || ''
        this.zipcode = this.user.zipcode || ''
        // if(this.courseRef && this.trainings.length) {
        //   this.training = this.trainings.filter(i => i.ref === this.courseRef)
        // }
      }
    },
    devisTypes() {
      // this.type = this.devisTypes ? this.devisTypes[0].toId : null
    },
  },
  methods: {
    reset() {
      this.firstname = this.user.firstname || ''
      this.lastname = this.user.lastname || ''
      this.email = this.user.email || ''
      this.phone = this.user.phone || ''
      this.country = this.user.countryCode || ''
      this.address = this.user.address || ''
      this.city = this.user.city || ''
      this.zipcode = this.user.zipcode || ''
      this.country = 'FR'
      this.message = ''
      this.accept = false
      this.training = null
      this.$refs.observer.reset()
    },
    AddCompanion() {
      window.dataLayer.push({
        event: 'demande_de_devis',
        category: 'contact',
        action: 'submit',
        label: 'devis',
      })
      this.$refs.observer.validate().then((success) => {
        if (!success) {
          return
        }
        let nbExperience = ''
        if (this.newBornsExperience == 'oui') {
          nbExperience = this.newBornsExperienceDuration
        } else {
          nbExperience = this.newBornsExperience
        }
        let data = {
          action: 'send_quote_request',
          firstname: this.firstname,
          lastname: this.lastname,
          phone: this.phone,
          country: this.country,
          email: this.email,
          company: this.company,
          address: this.address,
          zipcode: this.zipcode,
          city: this.city,
          message: this.message,
          courseRef: this.courseRef,
          courseTitle: this.courseTitle,
          training: this.selectedTraining,
          new_borns_experience: nbExperience,
          accept: this.accept,
          typeId: this.selectedType.id,
          typeName: this.selectedType.name,
          user: this.user,
        }
        this.isSaving = true
        this.$store
          .dispatch('app/sendQuoteRequest', data)
          .then((response) => {
            console.log('response = ', response)
            this.Toast.fire({
              icon: 'success',
              title: 'envoyée avec succées',
            }).then(() => {
              this.reset()
              this.quoteRequestModal.hide()
            })
          })
          .catch(() => {
            this.Toast.fire({
              icon: 'error',
              title: "Échec d'envoi",
            })
          })
          .finally(() => {
            this.isSaving = false
          })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-content {
  background-color: #f4f8fa !important;
  border-radius: 15px !important;
}

.text-md {
  font-size: 1rem;
}

.form-control-light {
  border-radius: 15px;
}

.vti__dropdown.open {
  background-color: transparent;
}
</style>
