<template>
    <div
      class="modal fade"
      id="check-my-eligibility-modal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content border-0">
          <div class="modal-header border-0">
            <h5 class="modal-title" id="cartModalLabel"></h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body pt-4">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="">
                    <h2 class="h2-primary fw-light mb-4"
                      >CANDIDATURE AU THÉRAPEUTIQUE BAIN BÉBÉ</h2
                    >
                    <div class="row">
                      <ValidationObserver ref="observer">
                        <form autocomplete="off">
                          <div class="row">
                            <div class="col-md-6 mb-3">
                              <validation-provider
                                name="lastname"
                                rules="required"
                                v-slot="{ errors }"
                                class=""
                                mode="eager"
                              >
                                <atom-input
                                  v-model="lastname"
                                  placeholder="Nom*"
                                  type="text"
                                  :input-class="{
                                    'form-control-light': true,
                                    'form-control-light-bordered': true,
                                    'form-control': true,
                                    'is-invalid': errors.length,
                                  }"
                                  label-class="d-none"
                                />
                              </validation-provider>
                            </div>
                            <div class="col-md-6 mb-3">
                              <validation-provider
                                name="firstname"
                                rules="required"
                                v-slot="{ errors }"
                                class=""
                                mode="eager"
                              >
                                <atom-input
                                  v-model="firstname"
                                  placeholder="Prénom*"
                                  type="text"
                                  :input-class="{
                                    'form-control-light': true,
                                    'form-control-light-bordered': true,
                                    'form-control': true,
                                    'is-invalid': errors.length,
                                  }"
                                  label-class="d-none"
                                />
                              </validation-provider>
                            </div>
                            <div class="col-md-6 mb-3">
                              <validation-provider
                                name="address"
                                rules="required"
                                v-slot="{ errors }"
                                class=""
                                mode="eager"
                              >
                                <atom-input
                                  v-model="address"
                                  placeholder="Adresse*"
                                  type="text"
                                  :input-class="{
                                    'form-control-light': true,
                                    'form-control-light-bordered': true,
                                    'form-control': true,
                                    'is-invalid': errors.length,
                                  }"
                                  label-class="d-none"
                                />
                              </validation-provider>
                            </div>
                            <div class="col-md-3 mb-3">
                              <validation-provider
                                name="ville"
                                rules="required"
                                v-slot="{ errors }"
                                class=""
                                mode="eager"
                              >
                                <atom-input
                                  v-model="ville"
                                  placeholder="Ville*"
                                  type="text"
                                  :input-class="{
                                    'form-control-light': true,
                                    'form-control-light-bordered': true,
                                    'form-control': true,
                                    'is-invalid': errors.length,
                                  }"
                                  label-class="d-none"
                                />
                              </validation-provider>
                            </div>
                            <div class="col-md-3 mb-3">
                              <validation-provider
                                name="codePostal"
                                rules="required"
                                v-slot="{ errors }"
                                class=""
                                mode="eager"
                              >
                                <atom-input
                                  v-model="codePostal"
                                  placeholder="Code Postal*"
                                  type="text"
                                  :input-class="{
                                    'form-control-light': true,
                                    'form-control-light-bordered': true,
                                    'form-control': true,
                                    'is-invalid': errors.length,
                                  }"
                                  label-class="d-none"
                                />
                              </validation-provider>
                            </div>
                            <div class="col-md-6 mb-3">
                              <validation-provider
                                name="department"
                                rules="required"
                                v-slot="{ errors }"
                                class=""
                                mode="eager"
                              >
                                <atom-input
                                  v-model="department"
                                  placeholder="Département*"
                                  type="text"
                                  :input-class="{
                                    'form-control-light': true,
                                    'form-control-light-bordered': true,
                                    'form-control': true,
                                    'is-invalid': errors.length,
                                  }"
                                  label-class="d-none"
                                />
                              </validation-provider>
                            </div>
                            <div class="col-md-6 mb-3">
                              <validation-provider
                                name="phone"
                                rules="required"
                                v-slot="{ errors }"
                                class=""
                                mode="eager"
                              >
                                <div class="col-lg-12 col-sm-12">
                                  <VuePhoneNumberInput
                                    v-model="phone"
                                    placeholder="Téléphone*"
                                    :clearable="true"
                                    :border-radius="15"
                                    :preferred-countries="['FR', 'BE']"
                                    :default-country-code="
                                      user ? user.countryCode : 'FR'
                                    "
                                    :translations="{
                                      countrySelectorLabel: 'Code pays',
                                      countrySelectorError: 'Choisir un pays',
                                      phoneNumberLabel: 'Numéro de téléphone',
                                      example: 'Exemple :',
                                    }"
                                    :class="{
                                      'form-control-light': true,
                                      'is-invalid': errors.length,
                                    }"
                                  />
                                </div>
                              </validation-provider>
                            </div>
                            <div class="col-md-6 mb-3">
                              <validation-provider
                                name="email"
                                rules="required|email"
                                v-slot="{ errors }"
                                class=""
                                mode="eager"
                              >
                                <div class="col-sm-12">
                                  <atom-input
                                    v-model="email"
                                    placeholder="Email*"
                                    type="text"
                                    :input-class="{
                                      'form-control-light': true,
                                      'form-control-light-bordered': true,
                                      'form-control': true,
                                      'is-invalid': errors.length,
                                    }"
                                    label-class="d-none"
                                  />
                                </div>
                              </validation-provider>
                            </div>
                            <div class="col-md-6 mb-3">
                              <validation-provider
                                name="metier"
                                rules="required"
                                v-slot="{ errors }"
                                class=""
                                mode="eager"
                              >
                                <treeselect
                                  v-model="selectedMetier"
                                  :multiple="false"
                                  :options="metiers"
                                  :class="{ 'is-invalid': errors.length }"
                                  no-options-text="Aucune option disponible."
                                  no-results-text="Aucun résultat trouvé ..."
                                  placeholder="Précisez votre métier*"
                                  :normalizer="normalizer"
                                />
                              </validation-provider>
                            </div>
  
                            <div class="col-md-12 mb-3">
                              <label
                                class="col-form-label text-dark d-block mb-3"
                              >
                                Diplômes :
                              </label>
                              <div v-if="user_diplomas.length" class="mb-3">
                                <div v-for="d in user_diplomas" :key="d.id">
                                  <div class="d-flex align-items-center">
                                    <div>- {{ d.name }}</div>
                                    <div>
                                      <button
                                        v-if="user_diplomas.length > 1"
                                        @click.stop.prevent="deleteDiploma(d.id)"
                                        class="btn p-0 ms-1"
                                      >
                                        <svg-icon
                                          icon-class="trash"
                                          className="trash"
                                        />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div v-else>
                                <div class="mb-3 fst-italic">Liste vide</div>
                              </div>
                              <div class="row">
                                <div class="col-lg-12">
                                  <div class="text-dark mb-3"
                                    >Ajouter un diplôme</div
                                  >
                                  <div class="row mb-3">
                                    <div class="col-md-6">
                                      <treeselect
                                        v-model="selectedDiplom"
                                        :multiple="false"
                                        :options="diplomas"
                                        no-options-text="Aucune option disponible."
                                        no-results-text="Aucun résultat trouvé ..."
                                        placeholder="Choisir un diplôme*"
                                        :normalizer="normalizer"
                                      />
                                    </div>
                                    <div class="col-md-6">
                                      <atom-button
                                        v-if="selectedDiplom"
                                        @click="addDiploma"
                                        btn-class="btn btn-primary-light"
                                        type="button"
                                        title="+ Ajouter"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
  
                            <div class="col-md-6 mb-3">
                              <atom-input
                                v-model="specialisation"
                                placeholder="Spécialisation"
                                type="text"
                                :input-class="{
                                  'form-control-light': true,
                                  'form-control-light-bordered': true,
                                  'form-control': true,
                                }"
                                label-class="d-none"
                              />
                            </div>
                            <div class="col-md-6 mb-3">
                              <validation-provider
                                name="current_ville"
                                rules="required"
                                v-slot="{ errors }"
                                class=""
                                mode="eager"
                              >
                                <atom-input
                                  v-model="current_ville"
                                  placeholder="Ville où vous exercez actuellement*"
                                  type="text"
                                  :input-class="{
                                    'form-control-light': true,
                                    'form-control-light-bordered': true,
                                    'form-control': true,
                                    'is-invalid': errors.length,
                                  }"
                                  label-class="d-none"
                                />
                              </validation-provider>
                            </div>
                            <div class="col-md-12 mb-3">
                              <validation-provider
                                name="question_1"
                                rules="required"
                                v-slot="{ errors }"
                                class=""
                                mode="eager"
                              >
                                <atom-textarea
                                  v-model="question_1"
                                  placeholder="Votre Réponse*"
                                  type="text"
                                  :input-class="{
                                    'form-control-light': true,
                                    'form-control-light-bordered': true,
                                    'form-control': true,
                                    'is-invalid': errors.length,
                                    'h-100': true,
                                  }"
                                  label-class="text-dark"
                                  label="Dans quelles structures et services avez-vous exercé (indiquer l’année et la durée - hors stage) :"
                                  rows="5"
                                />
                              </validation-provider>
                            </div>
                            <div class="col-md-12 mb-3">
                              <validation-provider
                                name="question_2"
                                rules="required"
                                v-slot="{ errors }"
                                class=""
                                mode="eager"
                              >
                                <atom-textarea
                                  v-model="question_2"
                                  placeholder="Votre Réponse*"
                                  type="text"
                                  :input-class="{
                                    'form-control-light': true,
                                    'form-control-light-bordered': true,
                                    'form-control': true,
                                    'is-invalid': errors.length,
                                    'h-100': true,
                                  }"
                                  label-class="text-dark"
                                  label="Nombre d’années d’expérience auprès des nouveaux nés de moins de 20 jours (hors stage) :"
                                  rows="5"
                                />
                              </validation-provider>
                            </div>
                            <div class="col-md-12 mb-3">
                              <validation-provider
                                name="question_3"
                                rules="required"
                                v-slot="{ errors }"
                                class=""
                                mode="eager"
                              >
                                <atom-textarea
                                  v-model="question_3"
                                  placeholder="Votre Réponse*"
                                  type="text"
                                  :input-class="{
                                    'form-control-light': true,
                                    'form-control-light-bordered': true,
                                    'form-control': true,
                                    'is-invalid': errors.length,
                                    'h-100': true,
                                  }"
                                  label-class="text-dark"
                                  label="Fréquence des soins auprès des nouveaux nés de moins de 20 jours (indiquer l’année et préciser le type de soins ) :"
                                  rows="5"
                                />
                              </validation-provider>
                            </div>
                            <div class="col-md-12 mb-3">
                              <validation-provider
                                name="question_4"
                                rules="required"
                                v-slot="{ errors }"
                                class=""
                                mode="eager"
                              >
                                <atom-textarea
                                  v-model="question_4"
                                  placeholder="Votre Réponse*"
                                  type="text"
                                  :input-class="{
                                    'form-control-light': true,
                                    'form-control-light-bordered': true,
                                    'form-control': true,
                                    'is-invalid': errors.length,
                                    'h-100': true,
                                  }"
                                  label-class="text-dark"
                                  label="Connaissances dans l’accompagnement de la femme enceinte et des pathologies de grossesse :"
                                  rows="5"
                                />
                              </validation-provider>
                            </div>
                            <div class="col-md-12 mb-3">
                              <validation-provider
                                name="question_5"
                                rules="required"
                                v-slot="{ errors }"
                                class=""
                                mode="eager"
                              >
                                <atom-textarea
                                  v-model="question_5"
                                  placeholder="Votre Réponse*"
                                  type="text"
                                  :input-class="{
                                    'form-control-light': true,
                                    'form-control-light-bordered': true,
                                    'form-control': true,
                                    'is-invalid': errors.length,
                                    'h-100': true,
                                  }"
                                  label-class="text-dark"
                                  label="Avez-vous des connaissances sur les accouchements ?"
                                  rows="5"
                                />
                              </validation-provider>
                            </div>
                            <div class="col-md-12 mb-3">
                              <validation-provider
                                name="question_6"
                                rules="required"
                                v-slot="{ errors }"
                                class=""
                                mode="eager"
                              >
                                <atom-textarea
                                  v-model="question_6"
                                  placeholder="Votre Réponse*"
                                  type="text"
                                  :input-class="{
                                    'form-control-light': true,
                                    'form-control-light-bordered': true,
                                    'form-control': true,
                                    'is-invalid': errors.length,
                                    'h-100': true,
                                  }"
                                  label-class="text-dark"
                                  label="Informations complémentaires :"
                                  rows="5"
                                />
                              </validation-provider>
                            </div>
                            <div class="col-md-12 mb-3">
                              <div class="mb-4">Rappel des prérequis :</div>
                              <div class="mb-4"
                                >En cas d’éligibilité , votre formation
                                Thérapeutique Bain Bébé ne pourra vous être
                                proposée qu’après avoir justifié des formations
                                pré requises selon votre profil.</div
                              >
                              <div class="mb-4">
                                Pour rappel, les formations : Allaitement Maternel
                                Initiation, Massage Bébé ainsi que Créer et animer
                                un atelier de prévention des accidents domestiques
                                et gestion de l’urgence à destination des parents
                                » sont recommandées.
                              </div>
                              <div class="mb-4">
                                Pour la formation Allaitement Maternel Initiation
                                (2j /14h / en visio), vous pourrez bénéficier
                                d’une aide au financement de 225€ sur le tarif de
                                cette formation dans le cadre de votre parcours de
                                praticienne en TBB
                              </div>
                              <div class="mb-3"
                                >Justificatif de diplôme à fournir en pièce jointe
                              </div>
  
                              <div v-if="user_files.length">
                                <div class="mb-3">
                                  <div v-for="f in user_files" :key="f.id">
                                    <div class="d-flex align-items-center">
                                      <div>- {{ f.name }}</div>
                                      <div>
                                        <button
                                          @click.stop.prevent="deleteFile(f.id)"
                                          class="btn p-0 ms-1"
                                        >
                                          <svg-icon
                                            icon-class="trash"
                                            className="trash"
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row mb-4">
                                <div class="col-md-6">
                                  <input
                                    @change="onFileChange"
                                    type="file"
                                    ref="fileupload"
                                    class="form-control h-auto form-control-file-visible"
                                    accept="application/pdf, image/png, image/jpeg, image/jpg"
                                  />
                                  <div class="text-muted small mt-2">
                                    Formats supportés : jpeg, png, jpg, pdf
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <atom-button
                                    v-if="selectedFile"
                                    @click="addFile"
                                    btn-class="btn btn-primary-light m-auto"
                                    type="button"
                                    title="+ Ajouter"
                                  />
                                </div>
                              </div>
                              <div
                                ><b
                                  >Prix de la formation Thérapeutique Bain Bébé
                                  (3jours/21h):</b
                                >
                                3200 euros<br />
                                <b>Délais de réponse au questionnaire :</b> sous
                                15 jours
                              </div>
                            </div>
                          </div>
  
                          <atom-button
                            @click="send"
                            btn-class="btn btn-primary mt-3 mb-3"
                            type="button"
                            title="Envoyer la demande"
                          />
                          <div class="row mb-3">
                            <div class="col-md-6">
                              <div class="text-md"> * Champ obligatoire</div>
                            </div>
                          </div>
                        </form>
                      </ValidationObserver>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import AtomButton from '@/components/atoms/AtomButton'
  import AtomInput from '@/components/atoms/AtomInput'
  import { mapFields } from 'vuex-map-fields'
  import AtomTextarea from '@/components/atoms/AtomTextarea.vue'
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import Treeselect from '@riophae/vue-treeselect'
  import VuePhoneNumberInput from 'vue-phone-number-input'
  import * as i18nIsoCountries from 'i18n-iso-countries'
  
  i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/fr.json'))
  const Swal = require('sweetalert2')
  
  export default {
    name: 'CheckMyEligibilityModal',
    components: {
      AtomButton,
      AtomInput,
      AtomTextarea,
      Treeselect,
      VuePhoneNumberInput,
    },
    data() {
      return {
        normalizer(node) {
          return {
            id: node.id,
            label: node.name,
          }
        },
        lastname: '',
        firstname: '',
        address: '',
        ville: '',
        codePostal: '',
        department: '',
        phone: '',
        email: '',
        metier: null,
        specialisation: '',
        current_ville: '',
        user_diplomas: [],
        user_files: [],
        question_1: '',
        question_2: '',
        question_3: '',
        question_4: '',
        question_5: '',
        question_6: '',
        selectedDiplom: null,
        selectedFile: null,
        selectedMetier: null,
  
        Toast: Swal.mixin({
          toast: true,
          position: 'center',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          },
        }),
        props: {
          defaultCountry: 'FR',
          preferredCountries: ['US', 'GB'],
          placeholder: 'Enter your phone',
          mode: 'international',
          inputOptions: {
            showDialCode: true,
          },
          disabledFormatting: false,
          wrapperClasses: 'country-phone-input',
        },
      }
    },
    watch: {
      user() {
        if (this.user) {
          this.firstname = this.user.firstname || ''
          this.lastname = this.user.lastname || ''
          this.email = this.user.email || ''
          this.phone = this.user.phone || ''
          this.props.defaultCountry = this.user.countryCode || 'FR'
          this.ville = this.user.city || ''
          this.codePostal = this.user.zipcode || ''
          this.address = this.user.address || ''
          this.user_diplomas = this.user.diplomas
        }
      },
      selectedMetier() {
        if (this.selectedMetier) {
          let d = this.metiers.find((i) => i.id === this.selectedMetier)
          if (d) this.metier = d
        }
      },
    },
    computed: {
      ...mapFields('app', [
        'metiers',
        'diplomas',
        'checkMyEligibilityModal',
        'user',
      ]),
    },
    methods: {
      addDiploma() {
        if (this.selectedDiplom) {
          let d = this.diplomas.find((i) => i.id === this.selectedDiplom)
          if (d) this.user_diplomas = [...this.user_diplomas, d]
          this.selectedDiplom = null
        }
      },
      onFileChange(event) {
        if (event.target.files.length > 0) {
          this.selectedFile = event.target.files[0]
        } else {
          this.selectedFile = null
        }
      },
      addFile() {
        if (this.selectedFile) {
          let fileObject = {
            id: 'file-' + this.user_files.length,
            name:
              this.selectedFile.name +
              ' [' +
              Math.round(this.selectedFile.size / 1024) +
              ' KB]',
            file: this.selectedFile,
          }
          this.user_files = [...this.user_files, fileObject]
          this.selectedFile = null
          this.$refs.fileupload.value = null
        }
      },
      deleteDiploma(id) {
        this.user_diplomas = this.user_diplomas.filter((i) => !(i.id === id))
      },
      deleteFile(id) {
        this.user_files = this.user_files.filter((i) => !(i.id === id))
      },
      send() {
        // TODO: Do we need this?
        // window.dataLayer.push({
        //   event: 'demande_de_devis',
        //   category: 'contact',
        //   action: 'submit',
        //   label: 'devis',
        // })
        console.log('send ')
        this.$refs.observer.validate().then((success) => {
          if (!success) {
            return
          }
          let formData = new FormData()
          formData.append('action', 'send_check_my_eligibility')
          formData.append('lastname', this.lastname)
          formData.append('firstname', this.firstname)
          formData.append('address', this.address)
          formData.append('ville', this.ville)
          formData.append('codePostal', this.codePostal)
          formData.append('department', this.department)
          formData.append('phone', this.phone)
          formData.append('email', this.email)
          formData.append('metier', this.metier ? this.metier.name : '')
          formData.append('specialisation', this.specialisation)
          formData.append('current_ville', this.current_ville)
          formData.append(
            'diplomes',
            this.user_diplomas.reduce((result, i) => result + i.name + ', ', '')
          )
  
          formData.append('question_1', this.question_1)
          formData.append('question_2', this.question_2)
          formData.append('question_3', this.question_3)
          formData.append('question_4', this.question_4)
          formData.append('question_5', this.question_5)
          formData.append('question_6', this.question_6)
          console.log('send formData', formData)
          this.user_files.forEach((file) => {
            formData.append(file.id, file.file)
          })
          console.log('send formData with file', formData)
          this.$store
            .dispatch('app/sendCheckMyEligibility', formData)
            .then(() => {
              this.Toast.fire({
                icon: 'success',
                title: 'envoyée avec succées',
              }).then(() => {
                this.checkMyEligibilityModal.hide()
                this.lastname = ''
                this.firstname = ''
                this.address = ''
                this.ville = ''
                this.codePostal = ''
                this.department = ''
                this.phone = ''
                this.email = ''
                this.metier = ''
                this.specialisation = ''
                this.current_ville = ''
                this.user_diplomas = []
                this.user_files = []
                this.question_1 = ''
                this.question_2 = ''
                this.question_3 = ''
                this.question_4 = ''
                this.question_5 = ''
                this.question_6 = ''
                this.$refs.observer.reset()
              })
            })
            .catch(() => {
              this.Toast.fire({
                icon: 'error',
                title: "Échec d'envoi",
              })
            })
        })
      },
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .modal-content {
    background-color: #f4f8fa !important;
    border-radius: 15px !important;
  }
  
  .text-md {
    font-size: 1rem;
  }
  
  .form-control-light {
    border-radius: 15px;
  }
  
  .vti__dropdown.open {
    background-color: transparent;
  }
  </style>
  